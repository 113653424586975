<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Program</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1200">
      <v-card flat class="white" height="900">
        <v-card-title class="text-h5 secondary--text"
          >Program Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row dense>
                    <!--Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_name"
                        label="Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Parent Fee-->
                    <v-col cols="12" sm="12" md="4">
                      <v-select
                        :items="valueListsStore.yesNo"
                        item-text="value"
                        item-value="id"
                        label="Parent Fee"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_parentFee"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Parent Fee FPL Threshold-->
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="d_parentFeeFPLThreshold"
                        label="Parent Fee FPL Threshold"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        :items="valueListsStore.activeStatus"
                        label="Status"
                        placeholder=" "
                        persistent-placeholder
                        item-text="value"
                        item-value="id"
                        v-model="f_status"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <!--Notes-->
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-model="d_note"
                        label="Notes"
                        rows="7"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Program Options</v-tab>
                <v-tab>CCDF/TANF Coding</v-tab>

                <!--Program Options-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <v-spacer />
                          <v-btn
                            rounded
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="addOption()"
                          >
                            <v-icon small left>fal fa-plus</v-icon>New</v-btn
                          >
                        </v-row>

                        <v-row dense :key="index" v-for="(item, index) in program_options">
                          <v-row dense>
                            <!--Name-->
                            <v-col cols="12" sm="12" md="4">
                              <v-text-field
                                v-model="item.d_name"
                                label="Name"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                hide-details
                              />
                            </v-col>

                            <!--Funding Source Code-->
                            <v-col cols="12" sm="2">
                              <v-autocomplete
                                :items="fundingSourcesStore.fundingSourcesValueList"
                                label="Funding Source Code"
                                placeholder=" "
                                persistent-placeholder
                                item-text="d_code"
                                item-value="id"
                                v-model="item.fk_fundingSourceID"
                                clearable
                                clear-icon="fal fa-times-circle"
                              />
                            </v-col>

                            <!--Funding Suffix-->
                            <v-col cols="12" sm="12" md="2">
                              <v-text-field
                                v-model="item.d_fundingCode"
                                label="Funding Suffix"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                hide-details
                              />
                            </v-col>

                            <!--Examine Tuition-->
                            <v-col cols="12" sm="12" md="2">
                              <v-select
                                :items="valueListsStore.yesNo"
                                item-text="value"
                                item-value="id"
                                label="Examine Tuition"
                                placeholder=" "
                                persistent-placeholder
                                v-model="item.f_examineTuition"
                                clearable
                                clear-icon="fal fa-times-circle"
                              />
                            </v-col>

                            <!--Parent Fee-->
                            <v-col cols="12" sm="12" md="1">
                              <v-autocomplete
                                :items="valueListsStore.yesNo"
                                label="Parent Fee"
                                placeholder=" "
                                persistent-placeholder
                                item-text="value"
                                item-value="id"
                                v-model="item.f_parentFee"
                              />
                            </v-col>

                            <!--Status-->
                            <v-col cols="12" sm="12" md="1">
                              <v-autocomplete
                                :items="valueListsStore.activeStatus"
                                label="Status"
                                placeholder=" "
                                persistent-placeholder
                                item-text="value"
                                item-value="id"
                                v-model="item.f_status"
                              />
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <!--Parent Fee Percentage 1-->
                            <v-col cols="12" sm="12" md="4">
                              <v-text-field
                                v-model="item.d_parentFeePercentage"
                                label="Parent Fee Percentage 1st Child"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>

                            <!--Parent Fee Percentage 2-->
                            <v-col cols="12" sm="12" md="4">
                              <v-text-field
                                v-model="item.d_parentFeePercentage2"
                                label="Parent Fee Percentage 2nd Child"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>

                            <!--Parent Fee PErcentage 3-->
                            <v-col cols="12" sm="12" md="4">
                              <v-text-field
                                v-model="item.d_parentFeePercentage3"
                                label="Parent Fee Percentage 3+ Children"
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-col>
                          </v-row>

                          <v-col cols="12" sm="12">
                            <v-divider />
                            <v-divider />
                            <v-divider />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--CCDF/TANF Coding-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <v-spacer />
                          <v-btn
                            rounded
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="addCoding()"
                          >
                            <v-icon small left>fal fa-plus</v-icon>New</v-btn
                          >

                          <!--Select All Button-->
                          <v-btn rounded @click="selectDeselectAllCoding()"
                            color="primary" class="py-0"
                            >
                            <v-icon small left>fal fa-check-double</v-icon>All</v-btn
                          >

                          <!--Duplicate Button-->
                          <v-tooltip
                            top
                            :color="programsStore.entry.selectedCodeID.length > 0 ? 'primary' : 'grey lighten-1'"
                          >
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="programsStore.entry.selectedCodeID.length > 0 ? openDuplicateDialog() : ''"
                              >
                                <v-icon
                                  small
                                  :color="programsStore.entry.selectedCodeID.length > 0 ? 'primary' : 'grey lighten-1'"
                                >
                                  fal fa-clone
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Duplicate Selection</span>
                          </v-tooltip>
                        </v-row>

                        <v-row dense :key="index" v-for="(item, index) in program_coding">
                          <!--Name-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="item.d_description"
                              label="Description"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              hide-details
                              @blur="
                                item.d_ncPrekSmi =
                                  item.d_description == 'CCDF' && id === 3 ? '0.75' : '';
                              "
                            />
                          </v-col>

                          <!--Program Option-->
                          <v-col cols="12" sm="12" md="5">
                            <v-autocomplete
                              :items="programsStore.entry.program_options"
                              label="Option"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="id"
                              v-model="item.fk_programOptionID"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--Funding Suffix-->
                          <v-col cols="12" sm="12" md="1">
                            <v-text-field
                              v-model="item.d_fundingCode"
                              label="Funding Suffix"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              hide-details
                            />
                          </v-col>

                          <!--Status-->
                          <v-col cols="12" sm="12" md="2">
                            <v-autocomplete
                              :items="valueListsStore.activeStatus"
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="id"
                              v-model="item.f_status"
                            />
                          </v-col>

                          <!--Checkbox-->
                          <v-col class="d-flex justify-end"
                            dense cols="12" sm="12" md="1">
                            <v-checkbox
                              dense
                              :key="item.id"
                              v-model="selectedCodeID"
                              :value="item.id"
                              color="primary"
                              class="pr-2"
                              multiple
                              >
                            </v-checkbox>
                          </v-col>

                          <!--US Citizen & NC Citizen-->
                          <v-col cols="12" sm="12" md="2">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              label="US Citizen & NC Resident"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="value"
                              v-model="item.d_additionalInfoUSCitizen"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--FPL-->
                          <v-col cols="12" sm="12" md="1">
                            <v-text-field
                              v-model="item.d_fpl"
                              label="FPL (uses <)"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              hide-details
                            />
                          </v-col>

                          <!--SMI-->
                          <v-col cols="12" sm="12" md="1"
                            v-if="id !== 3">
                            <v-text-field
                              v-model="item.d_smi"
                              label="SMI (uses <=)"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              hide-details
                            />
                          </v-col>

                          <!--NC-PreK SMI (only show for NC-Prek Program)-->
                          <v-col cols="12" sm="12" md="2"
                            v-else>
                            <v-text-field
                              v-model="item.d_ncPrekSmi"
                              label="SMI - NC PreK (uses <=)"
                              placeholder=" "
                              persistent-placeholder
                              disabled
                              flat
                              hide-details
                            />
                          </v-col>

                          <!--Family Structure-->
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[8]"
                              label="Family Structure"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="item.d_familyStructure"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--Reason For Eligibility-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[9]"
                              label="Reason For Eligibility"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="item.d_eligibilityReason"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <v-col cols="12" sm="12">
                            <v-divider />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Duplicate Dialog-->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Duplicate Coding?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDuplicateDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                Do you wish to duplicate all selected items?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            rounded
            color="primary"
            @click.native="duplicateCoding()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "programsStore/getEntryField",
  mutationType: "programsStore/updateEntryField"
});

export default {
  name: "ProgramEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      programsStore: state => state.programsStore,
      valueListsStore: state => state.valueListsStore,
      fundingSourcesStore: state => state.fundingSourcesStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "tab",
      "id",
      "d_name",
      "d_parentFeeFPLThreshold",
      "f_parentFee",
      "d_note",
      "f_status",
      "selectedCodeID"
    ]),
    ...mapMultiRowFields("programsStore", [
      "entry.j_options",
      "entry.program_options",
      "entry.program_coding"
    ])
  },
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("programsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      await this.loadValueLists();
      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["programsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("programsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("programsStore/update", entry);
        }
        this.cancelEntry();

        /* Reset Program List */
        this.$store.dispatch("progressStore/set", true);
        /* Grab current filters */
        const filters = this.$store.getters["programsStore/getFilters"];
        /* Store authorized program in store */
        await this.$store.dispatch("programsStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    },

    async loadValueLists() {
      await this.$store.dispatch("fundingSourcesStore/valueList");
      const data = {
        id: [8, 9]
      };
      await this.$store.dispatch("valueListsStore/items", data);
    },

    addOption() {
      this.$store.dispatch("programsStore/addOption");
    },

    removeOption(id) {
      let data;
      if (this.program_options[id].f_show === 1 || this.program_options[id].f_show === true) {
        data = {
          id,
          f_show: 0
        };
      } else {
        data = {
          id,
          f_show: 1
        };
      }
      this.$store.dispatch("programsStore/removeOption", data);
    },

    selectDeselectAllCoding() {
      this.$store.dispatch("programsStore/selectDeselectAllCoding");
    },

    async addCoding() {
      this.$store.dispatch("programsStore/addCoding");
    },

    removeCoding(id) {
      let data;
      if (this.program_coding[id].f_show === 1 || this.program_coding[id].f_show === true) {
        data = {
          id,
          f_show: 0
        };
      } else {
        data = {
          id,
          f_show: 1
        };
      }
      this.$store.dispatch("programsStore/removeCoding", data);
    },

    openDuplicateDialog() {
      this.showDialog = true;
    },

    closeDuplicateDialog() {
      this.showDialog = false;
    },

    async duplicateCoding() {
      /*var userChoice = confirm("Do you wish to duplicate all selected items?");
      if (userChoice) {*/
      //retrieve all selected code id's from the selectedCodeID state
      const selected = this.$store.getters["programsStore/getSelectedCodes"];
      const data = {
        items: selected
      };
      await this.$store.dispatch("programsStore/duplicateSelectedCode", data);

      //reload current data to update CCDF/TANF list
      var program_id = this.id;
      await this.$store.dispatch("programsStore/entry", program_id);
      //}
      this.closeDuplicateDialog();
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
.v-btn {
  margin-right: 15px;
}
</style>
